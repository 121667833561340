import { graphql, useStaticQuery } from "gatsby"

export default function useHomeData() {
  const data = useStaticQuery(graphql`
    query getB2cHomeData {
      markdownRemark(frontmatter: {url: {eq: "b2c-home"}}) {
        id
        frontmatter {
          title
          content_columns {
            images
            uploads
            list_content
            modal_content
          }
          subtitle
        }
      }
}

  `)
  return data.markdownRemark
}

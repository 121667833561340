import React from "react"
import Layout from "../../components/Layout"
import useHomeData from "../../static_queries/b2c/useHomeData"
import TripleContentColumns from "../../components/b2c/TripleContentColumns.js"

export default function Info() {
  const { frontmatter } = useHomeData()
  return (
    <Layout b2c={true} page="home" topTitle={frontmatter.title}>
      <TripleContentColumns columns={frontmatter.content_columns}/>
    </Layout>
  )
}

import React from "react"
import { navigate } from "gatsby"
import { Row, Col } from "antd"
import "antd/lib/grid/style/index.css"
import "tingle.js/dist/tingle.css"
import Carousel from "../../components/Carousel"
import styled from 'styled-components';


export const windowGlobal = typeof window !== "undefined" && window

const StyledContent = styled.div`
  ul {
    text-align: left;
  }
`;
const Centerer = styled.div`
  padding-top: 5px;
  text-align: center;
`;

class TripleContentColumns extends React.Component {
  openModal = content => {
    const tingle = require("tingle.js")
    windowGlobal.navigate = navigate
    windowGlobal.whyIndModal = new tingle.modal({})
    windowGlobal.whyIndModal.setContent(content)
    windowGlobal.whyIndModal.open()
  }

  render() {
    return (
      <div className="features-extended-inner">
        <Row
          gutter={24}
          className="feature-extended content-columns"
          type={"flex"}
        >
          {this.props.columns.map((col, index) => {
            return (
              <Col
                md={8}
                key={index}
                className={
                  col.list_content
                    ? "feature-extended-body checkmark"
                    : "feature-extended-image"
                }
                style={{
                  marginBottom: (index === 1) ? "30px" : "auto"
                }}
              >
                {col.list_content && (
                  <div style={{
                    marginTop: (index === 1) ? "100px" : "-10px"
                  }}>
                    <StyledContent
                      dangerouslySetInnerHTML={{ __html: col.list_content }}
                    />
                    {index === 1 && <Centerer>
                      <a
                        className="button button-primary cta-shadow"
                        onClick={() => navigate("/individuals/cart")}
                      >
                        Learn More / Redeem
                      </a>
                    </Centerer>}
                    <p
                      style={{
                        position: "absolute",
                        bottom: -60,
                        textAlign: "center",
                        left: 0,
                        right: 0,
                      }}
                      className="hero-cta"
                    >
                      {index !== 1 && (<a
                        className="button button-primary cta-shadow"
                        onClick={() =>
                          col.modal_content && col.modal_content.length > 10
                            ? this.openModal(col.modal_content)
                            : navigate("/individuals/cart")
                        }
                      >
                        Learn More
                      </a>)}
                      
                    </p>
                  </div>
                )}

                {col.uploads && col.uploads.length ? (
                  <div style={{ marginTop: 70 }}>
                    <Carousel images={col.uploads} />
                  </div>
                ) : null}
              </Col>
            )
          })}
        </Row>
      </div>
    )
  }
}

export default TripleContentColumns
